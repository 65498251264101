import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Logo from "../../Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import theme from "../../Theme";

const Header = ({ pageTitle }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    navigate("/");
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2)", // Custom shadow
        px: 2,
        py: 1,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box>
          <img src={Logo} width={70} />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700,
              color: theme.palette.primary.light,
            }}
          >
            {pageTitle}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Button
          variant="text"
          size="small"
          color="inherit"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
