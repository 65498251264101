import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import LayoutSideSidebarHeader from "./Layout/LayoutSideSidebarHeader";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import formatNumberIndia from "../../Utils/formatNumberIndia";
import theme from "../../Theme";
import formatDateTime from "../../Utils/fromatDateTime";
import DataWidget from "../../Components/DataWidget/DataWidget";
import ShowClientList from "../../Components/Modal/ShowClientList";
import Grid from "@mui/material/Grid2";
import { useSnackbar } from "notistack";
import ShowFilter from "../../Components/Drawer/ShowFilter";

const Dashboard = () => {
  // const api = process.env.URL;
  const apiUrl = process.env.REACT_APP_URL;

  const [rows, setRows] = useState([]);
  const [aggregatedRows, setAggregatedRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("All");
  const [nameFilter, setNameFilter] = useState("All");
  const [error, setError] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleTypeFilterChange = (event) => {
    setTypeFilter(event.target.value);
    setNameFilter("All"); // Reset name filter when type changes
  };

  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleReset = () => {
    setTypeFilter("All");
    setNameFilter("All");
    setSearchTerm("");
    setFilteredRows(rows);
    setAggregatedRows(aggregateData(rows));
  };

  const handleRowClick = (params) => {
    const personName = params.row.name;
    const personData = rows.filter((row) => row.name === personName);
    setSelectedPerson(personData);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPerson(null);
  };

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // "https://prime9accounts.api.tektreeinc.com/invoiceData/all"
          //"http://localhost:8000/invoiceData/all"
          `${apiUrl}/invoiceData/all`
        );
        const data = response.data;

        const allData = data.map((item) => ({
          id: item._id,
          type: item.userType.trim().toLowerCase(), // Normalize type
          name: item.userName,
          customerName: item.customerName,
          oneToThirtyDays: item.days1to30,
          thirtyOneToSixtyDays: item.days31to60,
          sixtyOneToNinetyDays: item.days61to90,
          aboveNinetyDays: item.above90Days,
          total: item.total,
          date: item.updatedAt,
          invoices: item.Invoices, // Include the invoices field
        }));

        setRows(allData);
        setFilteredRows(allData);
        setAggregatedRows(aggregateData(allData));
      } catch (err) {
        setError("Failed to fetch data");
        console.error(err.message);
      }
    };

    fetchData();
  }, []);

  // Update new data
  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}/invoiceData/update`, // Endpoint to trigger the update
        {} // Request payload (if needed, otherwise, you can pass an empty object)
      );
      setIsLoading(false);
      // Check if the response contains a success message
      if (response.status === 200) {
        // Assuming the response contains updated data
        const updatedData = response.data.data || [];

        // Update your state with the new data
        const allData = updatedData.map((item) => ({
          id: item._id,
          type: item.userType.trim().toLowerCase(), // Normalize type
          name: item.userName,
          customerName: item.customerName,
          oneToThirtyDays: item.days1to30,
          thirtyOneToSixtyDays: item.days31to60,
          sixtyOneToNinetyDays: item.days61to90,
          aboveNinetyDays: item.above90Days,
          total: item.total,
          date: item.updatedAt,
        }));

        setRows(allData);
        setFilteredRows(allData);
        setAggregatedRows(aggregateData(allData));

        // Optionally, display a success message (you can use a notification system)
        // alert(response.data.message); // Display success message from backend
        enqueueSnackbar(response.data.message, {
          variant: "success",
        });
      }
    } catch (err) {
      setError("Failed to refresh data");
      console.error(err.message);
      enqueueSnackbar(err.message, {
        variant: "error",
      });

      // Handle the error response from backend
      // if (err.response && err.response.data && err.response.data.error) {
      //   alert(`Error: ${err.response.data.error}`); // Show the error message from backend
      // } else {
      //   alert("An unexpected error occurred.");
      // }
    }
  };

  // Filter rows based on the current filters
  useEffect(() => {
    const filterRows = () => {
      let filtered = rows;

      if (typeFilter !== "All") {
        filtered = filtered.filter((row) => row.type === typeFilter);
      }

      if (nameFilter !== "All") {
        filtered = filtered.filter((row) => row.name === nameFilter);
      }

      if (searchTerm !== "") {
        filtered = filtered.filter((row) => {
          const searchString = [
            row.name,
            row.type,
            row.customerName,
            row.oneToThirtyDays,
            row.thirtyOneToSixtyDays,
            row.sixtyOneToNinetyDays,
            row.aboveNinetyDays,
            row.total,
          ]
            .map((value) =>
              value !== null && value !== undefined
                ? value.toString().toLowerCase()
                : ""
            )
            .join(" ");

          return searchString.includes(searchTerm.toLowerCase());
        });
      }

      setFilteredRows(filtered);
      setAggregatedRows(aggregateData(filtered));
    };

    filterRows();
  }, [searchTerm, typeFilter, nameFilter, rows]);

  const aggregateData = (data) => {
    const aggregation = {};

    data.forEach((item) => {
      const key = `${item.name}-${item.type}`;
      if (!aggregation[key]) {
        aggregation[key] = {
          id: key,
          name: item.name,
          type: item.type,
          clients: new Set(), // Track unique clients
          oneToThirtyDays: 0,
          thirtyOneToSixtyDays: 0,
          sixtyOneToNinetyDays: 0,
          aboveNinetyDays: 0,
          total: 0,
        };
      }
      aggregation[key].clients.add(item.customerName); // Add the customer name to the set
      aggregation[key].oneToThirtyDays += item.oneToThirtyDays || 0;
      aggregation[key].thirtyOneToSixtyDays += item.thirtyOneToSixtyDays || 0;
      aggregation[key].sixtyOneToNinetyDays += item.sixtyOneToNinetyDays || 0;
      aggregation[key].aboveNinetyDays += item.aboveNinetyDays || 0;
      aggregation[key].total += item.total || 0;
    });

    //return Object.values(aggregation);
    // Convert the Set to the client count
    return Object.values(aggregation).map((item) => ({
      ...item,
      clientsCount: item.clients.size, // Get the number of unique clients
    }));
  };

  // Generate unique names based on the selected type filter
  const filteredNames =
    typeFilter === "All"
      ? rows.map((row) => row.name)
      : rows.filter((row) => row.type === typeFilter).map((row) => row.name);

  const uniqueNames = ["All", ...new Set(filteredNames)];

  const columns = [
    { field: "name", headerName: "Name", flex: 1, minWidth: 300 },
    { field: "type", headerName: "Type", width: 180 },
    {
      field: "clientsCount",
      headerName: "No.of Accounts", // New column for client count
      width: 180,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return <Box>{params.value}</Box>;
      },
    },
    {
      field: "oneToThirtyDays",
      headerName: "1 to 30 Days",
      width: 180,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return <Box>{formatNumberIndia(params.value)}</Box>;
      },
    },
    {
      field: "thirtyOneToSixtyDays",
      headerName: "31 to 60 Days",
      width: 180,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return <Box>{formatNumberIndia(params.value)}</Box>;
      },
    },
    {
      field: "sixtyOneToNinetyDays",
      headerName: "61 to 90 Days",
      width: 180,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return <Box>{formatNumberIndia(params.value)}</Box>;
      },
    },
    {
      field: "aboveNinetyDays",
      headerName: "Above 90 Days",
      width: 180,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return <Box>{formatNumberIndia(params.value)}</Box>;
      },
    },
    {
      field: "total",
      headerName: "Total",
      width: 180,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return <Box>{formatNumberIndia(params.value)}</Box>;
      },
    },
  ];

  //////////////////
  // Function to calculate the sum of `total` values
  const getTotalSum = (data) => {
    return data.reduce((sum, item) => {
      // Add the `total` field if it exists and is a number
      if (!isNaN(item.total)) {
        sum += Number(item.total);
      }
      return sum;
    }, 0);
  };

  //////////
  const [sortModel, setSortModel] = useState([
    {
      field: "total", // Initial column to sort by
      sort: "desc", // 'asc' for ascending, 'desc' for descending
    },
  ]);

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  //////////////////////////////
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight); // Update height when the window is resized
    };

    window.addEventListener("resize", handleResize); // Add resize event listener

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener on unmount
    };
  }, []);

  //////////////////////////////////////
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const toggleShowFilterDrawer = () => setShowFilterDrawer(!showFilterDrawer);

  return (
    <LayoutSideSidebarHeader pageTitle="Receivables Aging Report">
      <Box
        sx={{
          height: {
            xs: "calc(100% - 32px)",
            md: "calc(100% - 64px)",
          },
          mx: 2,
          my: { xs: 2, md: 4 },
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box>
          <Grid container spacing={1}>
            <Grid item size={{ xs: 12, sm: 12, md: 4, lg: 3, xl: 2 }}>
              <DataWidget
                title={"Total"}
                value={getTotalSum(aggregatedRows)}
                color="secondary"
                type="currency"
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 12, md: 8, lg: 9, xl: 10 }}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Grid container spacing={0.5}>
                  <Grid item size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                    <TextField
                      label="Search"
                      variant="filled"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel>Filter by Type...</InputLabel>
                      <Select
                        value={typeFilter}
                        onChange={handleTypeFilterChange}
                        size="small"
                        color="primary"
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="salesperson">Salesperson</MenuItem>
                        <MenuItem value="ap-reporter">AP Reporter</MenuItem>
                        <MenuItem value="ts-reporter">TS Reporter</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel>Filter by Name...</InputLabel>
                      <Select
                        value={nameFilter}
                        onChange={handleNameFilterChange}
                        size="small"
                        color="primary"
                      >
                        {uniqueNames.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item size={{ xs: 4, sm: 4 }}>
                    <Button
                      variant="contained"
                      onClick={handleReset}
                      color="primary"
                      sx={{ whiteSpace: "nowrap" }}
                      size="small"
                    >
                      Reset Filters
                    </Button>
                  </Grid>
                  <Grid item size={{ xs: 8, sm: 8 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        color="inherit"
                        variant="text"
                        size="small"
                        onClick={handleRefresh}
                      >
                        Refresh
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <Typography
                      sx={{
                        fontSize: 11,
                        color: theme.palette.grey[600],
                        textAlign: "right",
                      }}
                    >
                      Last refresh On :{" "}
                      {rows[0]
                        ? formatDateTime(rows[0]?.date)
                        : formatDateTime(new Date())}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "flex",
                    md: "none",

                    justifyContent: "space-between",
                  },
                }}
              >
                <Box>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={toggleShowFilterDrawer}
                  >
                    Filters
                  </Button>
                </Box>
                <Box>
                  <Button
                    color="inherit"
                    variant="text"
                    size="small"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box flex={1} height={"100%"} overflow={"hidden"}>
          <DataGrid
            rows={aggregatedRows}
            columns={columns}
            onRowClick={handleRowClick}
            disableRowSelectionOnClick
            rowHeight={50} // Sets the height of each row to 50px
            sortModel={sortModel} // Dynamically bind the sortModel state
            onSortModelChange={handleSortModelChange} // Update the sortModel on user interaction
            sx={{
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },

              // Styling individual header cells
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
              },
              // Customizing the header title
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 600, // Bold text in the header
                letterSpacing: 1,
              },
            }}
          />
        </Box>
      </Box>

      <ShowClientList
        open={openModal}
        onClose={handleCloseModal}
        selectedPerson={selectedPerson}
      />
      {/* <ShowFilter open={true} /> */}
      <Drawer
        anchor={"bottom"}
        open={showFilterDrawer}
        onClose={toggleShowFilterDrawer}
      >
        <Box p={2} pb={5}>
          <Typography variant="h6" fontWeight={700} mb={1}>
            Filters
          </Typography>
          <Grid container spacing={2}>
            <Grid item size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
              <TextField
                label="Search"
                variant="filled"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Filter by Type...</InputLabel>
                <Select
                  value={typeFilter}
                  onChange={handleTypeFilterChange}
                  size="small"
                  color="primary"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="salesperson">Salesperson</MenuItem>
                  <MenuItem value="ap-reporter">AP Reporter</MenuItem>
                  <MenuItem value="ts-reporter">TS Reporter</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Filter by Name...</InputLabel>
                <Select
                  value={nameFilter}
                  onChange={handleNameFilterChange}
                  size="small"
                  color="primary"
                >
                  {uniqueNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <Button
                onClick={handleReset}
                color="primary"
                sx={{ whiteSpace: "nowrap" }}
                size="small"
              >
                Reset Filters
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </LayoutSideSidebarHeader>
  );
};

export default Dashboard;
