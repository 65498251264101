import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../Theme";
import Logo from "../../Assets/Images/logo.png";

import { UserDetails } from "../../Data/UserDetails";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (localStorage.getItem("isAuthenticated")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleLogin = () => {
    const normalizedEmail = email.toLowerCase(); // Convert email to lowercase
    const user = UserDetails.find(
      (user) =>
        user.userEmail.toLowerCase() === normalizedEmail &&
        user.userPassword === password
    );

    if (user) {
      console.log("true");
      localStorage.setItem("isAuthenticated", "true");
      navigate("/dashboard");
    } else {
      console.log("false");
      enqueueSnackbar("Invalid email or password. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: theme.palette.secondary.light,
      }}
    >
      <Paper sx={{ width: "80%", maxWidth: 500, p: 5 }}>
        <Box>
          <img src={Logo} width="80" />
        </Box>
        <Box my={2}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            Welcome back
            <Typography variant="h4" color="secondary" component={"span"}>
              .
            </Typography>
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: 400,
            }}
          >
            Gain valuable insights and track key metrics with ease.
          </Typography>
        </Box>
        <Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <TextField
              fullWidth
              id="filled-basic"
              label="User Id"
              variant="filled"
              color="primary"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <TextField
              fullWidth
              id="filled-basic"
              label="Password"
              type="password"
              variant="filled"
              color="primary"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            /> */}
            {/* <TextField
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="filled"
              color="primary"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              componentsProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            /> */}
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="filled"
              color="primary"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* <Box>
                <FormControlLabel
                  control={
                    <Checkbox defaultChecked color="secondary" size="small" />
                  }
                  label="Remember Me"
                />
              </Box> */}
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ width: "30%" }}
              onClick={handleLogin}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
