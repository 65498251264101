import { Box, Button, Divider, Modal, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import formatNumberIndia from "../../Utils/formatNumberIndia";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../../Theme";
import formatDateTime from "../../Utils/fromatDateTime";

const invoiceList = [
  {
    invoiceNumber: "INV-1598",
    invoiceDate: "2024-01-21T07:10:59.001Z",
    invoiceAmount: "225000.00",
    invoiceLink: "http://example.com/invoice/INV-1598",
  },
  {
    invoiceNumber: "INV-4327",
    invoiceDate: "2024-02-23T12:25:42.001Z",
    invoiceAmount: "18600.40",
    invoiceLink: "http://example.com/invoice/INV-4327",
  },
  {
    invoiceNumber: "INV-8941",
    invoiceDate: "2024-03-25T14:35:26.001Z",
    invoiceAmount: "22200.00",
    invoiceLink: "http://example.com/invoice/INV-8941",
  },
  {
    invoiceNumber: "INV-2468",
    invoiceDate: "2024-04-27T09:45:51.001Z",
    invoiceAmount: "18560.60",
    invoiceLink: "http://example.com/invoice/INV-2468",
  },
  {
    invoiceNumber: "INV-7213",
    invoiceDate: "2024-07-29T13:52:30.001Z",
    invoiceAmount: "20685.80",
    invoiceLink: "http://example.com/invoice/INV-7213",
  },
  {
    invoiceNumber: "INV-8109",
    invoiceDate: "2024-07-31T16:00:55.001Z",
    invoiceAmount: "22135.90",
    invoiceLink: "http://example.com/invoice/INV-8109",
  },
  {
    invoiceNumber: "INV-5823",
    invoiceDate: "2024-08-02T10:20:15.001Z",
    invoiceAmount: "20430.00",
    invoiceLink: "http://example.com/invoice/INV-5823",
  },
  {
    invoiceNumber: "INV-3254",
    invoiceDate: "2024-10-04T11:35:25.001Z",
    invoiceAmount: "22005.45",
    invoiceLink: "http://example.com/invoice/INV-3254",
  },
  {
    invoiceNumber: "INV-2187",
    invoiceDate: "2024-11-06T08:50:40.001Z",
    invoiceAmount: "21999.99",
    invoiceLink: "http://example.com/invoice/INV-2187",
  },
  {
    invoiceNumber: "INV-7634",
    invoiceDate: "2024-12-08T14:05:55.001Z",
    invoiceAmount: "21800.25",
    invoiceLink: "http://example.com/invoice/INV-7634",
  },
];

const ShowClientList = ({ open, onClose, selectedPerson }) => {
  const [selectedClient, setSelectedClient] = useState(null); // State for storing the selected client

  // Function to handle row click and set the selected client
  const handleRowClick = (params) => {
    setSelectedClient(params.row); // Store selected row data
  };
  const invoiceSelectData = selectedClient?.invoices || [];
  const sortedInvoiceList = invoiceSelectData.sort(
    (a, b) => new Date(b.invoice_date) - new Date(a.invoice_date)
  );

  //////////
  const [sortModel, setSortModel] = useState([
    {
      field: "total", // Initial column to sort by
      sort: "desc", // 'asc' for ascending, 'desc' for descending
    },
  ]);

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  return (
    <Box>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "1400px",
            width: "90%",
            height: "90%",
            overflow: "auto",
            bgcolor: theme.palette.secondary.main,
            boxShadow: 24,
            p: { xs: 2, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              height: "100%",
              bgcolor: theme.palette.secondary.light,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box px={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 800,
                      color: theme.palette.primary.dark,
                      letterSpacing: 1,
                    }}
                    variant="h5"
                  >
                    {selectedPerson && `${selectedPerson[0].name}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: theme.palette.grey[600],
                      letterSpacing: 1,
                    }}
                  >
                    Customers Detail
                  </Typography>
                </Box>
                <Box>
                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box flex={1} px={2} overflow={"hidden"}>
              <DataGrid
                rows={selectedPerson || []}
                onRowClick={handleRowClick} // Handle row click
                columns={[
                  {
                    field: "customerName",
                    headerName: "Customer Name",
                    flex: 1,
                    minWidth: 400,
                  },
                  {
                    field: "oneToThirtyDays",
                    headerName: "1 to 30 Days",
                    width: 180,
                    headerAlign: "right",
                    align: "right",
                    renderCell: (params) => {
                      return <Box>{formatNumberIndia(params.value)}</Box>;
                    },
                  },
                  {
                    field: "thirtyOneToSixtyDays",
                    headerName: "31 to 60 Days",
                    width: 180,
                    headerAlign: "right",
                    align: "right",
                    renderCell: (params) => {
                      return <Box>{formatNumberIndia(params.value)}</Box>;
                    },
                  },
                  {
                    field: "sixtyOneToNinetyDays",
                    headerName: "61 to 90 Days",
                    width: 180,
                    headerAlign: "right",
                    align: "right",
                    renderCell: (params) => {
                      return <Box>{formatNumberIndia(params.value)}</Box>;
                    },
                  },
                  {
                    field: "aboveNinetyDays",
                    headerName: "Above 90 Days",
                    width: 180,
                    headerAlign: "right",
                    align: "right",
                    renderCell: (params) => {
                      return <Box>{formatNumberIndia(params.value)}</Box>;
                    },
                  },
                  {
                    field: "total",
                    headerName: "Total",
                    width: 180,
                    headerAlign: "right",
                    align: "right",
                    renderCell: (params) => {
                      return <Box>{formatNumberIndia(params.value)}</Box>;
                    },
                  },
                ]}
                sortModel={sortModel} // Dynamically bind the sortModel state
                onSortModelChange={handleSortModelChange} // Update the sortModel on user interaction
                rowHeight={50} // Sets the height of each row to 50px
                sx={{
                  "& .MuiDataGrid-row:hover": {
                    cursor: "pointer",
                  },

                  // Styling individual header cells
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  },
                  // Customizing the header title
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: 600, // Bold text in the header
                    letterSpacing: 1,
                  },
                }}
                disableRowSelectionOnClick
              />
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Client Details Modal */}
      {selectedClient && (
        <Modal
          open={Boolean(selectedClient)}
          onClose={() => setSelectedClient(null)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "600px",
              width: "90%",
              height: "90%",
              maxHeight: 600,
              overflow: "auto",
              bgcolor: theme.palette.secondary.main,
              boxShadow: 24,
              p: { xs: 2, md: 3 },
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                height: "100%",
                bgcolor: theme.palette.secondary.light,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: theme.palette.grey[600],
                        letterSpacing: 1,
                      }}
                    >
                      {selectedPerson && `${selectedPerson[0].name}`}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 800,
                        color: theme.palette.primary.dark,
                        letterSpacing: 1,
                      }}
                      variant="h5"
                    >
                      {selectedClient.customerName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: theme.palette.grey[600],
                        letterSpacing: 1,
                      }}
                    >
                      Invoice Details:
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => setSelectedClient(null)}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box flex={1} overflow={"hidden"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,

                    height: "100%",
                    overflow: "auto",
                  }}
                >
                  {sortedInvoiceList.map((item, key) => {
                    return (
                      <Paper
                        key={key}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: {
                            xs: "",
                            sm: "flex-end",
                          },
                          gap: {
                            xs: 1,
                            sm: 3,
                          },
                          p: 2,
                          flexDirection: {
                            xs: "column",
                            sm: "row",
                          },
                          opacity: item.invoice_amount === 0 ? 0.4 : 1,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              color: theme.palette.grey[700],
                              fontWeight: 700,
                              fontSize: 16,
                            }}
                          >
                            {item.invoice_id}
                          </Typography>
                          <Typography
                            sx={{
                              color: theme.palette.grey[700],
                              fontWeight: 700,
                              fontSize: 11,
                            }}
                          >
                            {formatDateTime(item.invoice_date)}
                          </Typography>
                          <a
                            href={`https://books.zoho.in/app/60019298028#/invoices/${item.invoice_id}`}
                            target="_blank"
                          >
                            View
                          </a>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Divider
                            sx={{ borderStyle: "dashed" }}
                            variant="fullWidth"
                          />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              color: theme.palette.grey[700],
                              fontWeight: 700,
                              fontSize: 30,
                            }}
                          >
                            {formatNumberIndia(item.invoice_amount)}
                          </Typography>
                        </Box>
                      </Paper>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default ShowClientList;
