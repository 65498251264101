const UserDetails = [
  {
    userId: 1,
    userName: "omkar",
    userEmail: "omkar@gmail.com",
    userPassword: "123456",
  },
  {
    userId: 2,
    userName: "Raghu",
    userEmail: "raghu@tektreeinc.com",
    userPassword: "R123$",
  },
];

export { UserDetails };
