import React from "react";
import { ThemeProvider } from "@mui/material";
import theme from "./Theme";
// import { Route, Router, Routes } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Dashboard, Login } from "./Pages";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
