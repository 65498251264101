// import { Box } from "@mui/material";
// import React from "react";

// import Header from "../../../Components/Section/Header";
// import Footer from "../../../Components/Section/Footer";

// const ScrollView = ({ children }) => {
//   return (
//     <Box
//       sx={{
//         height: "100%",
//         overflowY: "auto",
//         width: "100vw",
//         overflow: "hidden",

//         "&::-webkit-scrollbar": {
//           width: "4px", // width of the scrollbar
//         },
//         "&::-webkit-scrollbar-track": {
//           backgroundColor: "#F3F4F8", // color of the track
//         },
//         "&::-webkit-scrollbar-thumb": {
//           backgroundColor: "#FE4F18", // color of the thumb
//           borderRadius: "4px", // roundness of the thumb
//         },
//         "&::-webkit-scrollbar-thumb:hover": {
//           backgroundColor: "#B23711", // color of the thumb on hover
//         },
//       }}
//     >
//       <Box sx={{ px: 1, pt: 2, pb: 1 }}>{children}</Box>
//     </Box>
//   );
// };

// const LayoutSideSidebarHeader = ({ children, pageTitle }) => {
//   return (
//     <Box sx={{ display: "flex", height: "100dvh" }}>
//       <Box sx={{ width: 0 }}></Box>
//       <Box
//         flex={1}
//         sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 0.2 }}
//       >
//         <Box>
//           <Header pageTitle={pageTitle} />
//         </Box>
//         <Box sx={{ flex: 1, overflow: "auto" }}>{children}</Box>
//         <Box>
//           <Footer />
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default LayoutSideSidebarHeader;

import { Box } from "@mui/material";
import React from "react";

import Header from "../../../Components/Section/Header";
import Footer from "../../../Components/Section/Footer";

const LayoutSideSidebarHeader = ({ children, pageTitle }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100dvh" }}>
      <Box>
        <Header pageTitle={pageTitle} />
      </Box>
      <Box sx={{ flex: 1, overflow: "hidden" }}>{children}</Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default LayoutSideSidebarHeader;
