import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../Theme";

const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        px: 2,
        py: 1,
        background: theme.palette.secondary.main,
      }}
    >
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 400,
          wordSpacing: 1.2,
          letterSpacing: 0.7,
          fontFamily: "Kumbh+Sans",
        }}
      >
        Copyright © 2024 Prime9News. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
