import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../Theme";

// Utility function for formatting numbers
const formatNumber = (num) => {
  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

// Utility function for converting numbers to Indian words
const numberToIndianWords = (num) => {
  const a = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const b = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const c = ["", "Thousand", "Lakh", "Crore"];

  if (num === 0) return "Zero";

  const makeGroup = (n) => {
    let str = "";
    if (n >= 100) {
      str += `${a[Math.floor(n / 100)]} Hundred `;
      n %= 100;
    }
    if (n >= 20) {
      str += `${b[Math.floor(n / 10)]} `;
      n %= 10;
    }
    if (n > 0) {
      str += `${a[n]} `;
    }
    return str.trim();
  };

  const splitIntoGroups = (n) => {
    const groups = [];
    groups.push(n % 1000); // First group of 3 digits
    n = Math.floor(n / 1000);

    while (n > 0) {
      groups.push(n % 100); // Groups of 2 digits (Lakh, Crore, etc.)
      n = Math.floor(n / 100);
    }

    return groups.reverse();
  };

  const groups = splitIntoGroups(num);
  const wordArray = groups
    .map((group, index) =>
      group ? `${makeGroup(group)} ${c[groups.length - index - 1]}` : ""
    )
    .filter(Boolean);

  return wordArray.join(" ").trim();
};

const DataWidget = ({
  color = "default", //primary, secondary, info, error, warning, success
  title = "Title",
  value,
  type = "default", //default, number,currency
}) => {
  return (
    <Box
      sx={{
        p: {
          xs: 1.5,
          md: 2,
        },
        bgcolor: color !== "default" ? theme.palette[color]?.main : "white",
        borderRadius: 2,
        boxShadow: 2,
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
      }}
    >
      <Typography
        sx={{
          fontSize: 12,
          color:
            color !== "default"
              ? theme.palette[color]?.contrastText
              : theme.palette.grey[400],
          textTransform: "uppercase",
          letterSpacing: 0.2,
          fontWeight: 400,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: 18,
            md: 30,
          },
          fontWeight: 800,
          color:
            color !== "default"
              ? theme.palette[color]?.contrastText
              : theme.palette.grey[700],
          lineHeight: 1,
        }}
      >
        {type === "number"
          ? value
          : type === "currency"
          ? formatNumber(value)
          : value}
        {/* {formatNumber(value)} */}
      </Typography>
      <Typography
        noWrap
        sx={{
          fontSize: 11,
          fontWeight: 300,
          color:
            color !== "default"
              ? theme.palette[color]?.contrastText
              : theme.palette.grey[400],
          lineHeight: 1,
          opacity: color !== "default" ? 0.7 : 1,
          letterSpacing: 0.5,
        }}
      >
        {type === "number"
          ? numberToIndianWords(value)
          : type === "currency"
          ? numberToIndianWords(value)
          : ""}
        {/* {numberToIndianWords(value)} */}
      </Typography>
    </Box>
  );
};

export default DataWidget;
