import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    // primary: {
    //   default: "31, 35, 56",
    //   dark: "#1F2338",
    //   main: "#2D3250",
    //   light: "#575B73",
    //   contrastText: "#FFF",
    // },
    // secondary: {
    //   default: "174, 124, 85",
    //   dark: "#AE7C55",
    //   main: "#F8B179",
    //   light: "#FAC8A1",
    //   contrastText: "#1F2338",
    // },

    // text: {
    //   dark: "#343135",
    //   main: "#4B464C",
    //   light: "#6F6B70",
    // },

    primary: {
      default: "254, 79, 24",
      dark: "#FE4F18",
      main: "#FE4F18",
      light: "#FE7246",
      contrastText: "#FFF",
    },
    secondary: {
      default: "243, 244, 248",
      dark: "#AAABAE",
      main: "#F3F4F8",
      light: "#F7F7FA",
      contrastText: "#141517",
    },

    text: {
      dark: "#0E0F10",
      main: "#141517",
      light: "#434445",
    },

    info: {
      dark: "#185771",
      main: "#227CA1",
      light: "#4E96B4",
      contrastText: "#FFF",
    },
    success: {
      dark: "#6E903E",
      main: "#9DCD59",
      light: "#B1D77A",
      contrastText: "#343135",
    },
    warning: {
      dark: "#B08631",
      main: "#FCC046",
      light: "#FDCD6B",
      contrastText: "#343135",
    },
    error: {
      dark: "#A84B4D",
      main: "#F06B6E",
      light: "#F3898B",
      contrastText: "#FFF",
    },

    // Additional accent colors
  },
  typography: {
    //fontFamily: "Oxygen, sans-serif",
    //fontFamily: "Raleway, sans-serif",
    fontFamily: "Montserrat, sans-serif",
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "inherit", // Makes button text inherit the case style
        },
      },
    },
  },

  // components: {
  //   MuiBackdrop: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: "rgba(0, 0, 0, 0.8)", // Customize the backdrop color here
  //       },
  //     },
  //   },
  //   MuiIconButton: {
  //     styleOverrides: {
  //       sizeXsmall: {
  //         width: "20px",
  //         height: "20px",
  //         padding: "6px",
  //         "& .MuiSvgIcon-root": {
  //           fontSize: "14px",
  //         },
  //       },
  //     },
  //   },
  //   MuiDataGrid: {
  //     styleOverrides: {
  //       root: {
  //         border: "none",
  //         //fontSize: "16px",
  //       },
  //       columnHeaders: {
  //         backgroundColor: "#FFFFFF",
  //         fontSize: "15px",
  //         color: "#4524AA",
  //       },
  //       cell: {
  //         backgroundColor: "#FFFFFF",
  //         paddingTop: "8px",
  //         paddingBottom: "8px",
  //         display: "flex",
  //         alignItem: "center",
  //       },
  //       selectedRow: {
  //         backgroundColor: "red",
  //       },
  //       footerContainer: {
  //         backgroundColor: "#FFFFFF",
  //       },
  //       checkbox: {
  //         color: "#4524AA",
  //       },
  //     },
  //   },

  //   MuiAccordion: {
  //     styleOverrides: {
  //       root: {
  //         //backgroundColor: "red", // Customize the default background color
  //         // border: "1px solid #ccc", // Add border if needed
  //         // borderRadius: "4px", // Customize the border radius
  //         boxShadow: "none", // Remove shadow
  //         // margin: 0,
  //         //padding: 3,
  //         minHeight: 30,
  //       },
  //       expanded: {
  //         backgroundColor: "blue", // Customize background when expanded
  //         margin: 0,
  //         padding: 30,
  //       },
  //     },
  //   },
  //   MuiAccordionSummary: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: "#EEEAFB", // Customize the summary background color
  //         color: "#000000", // Customize the text color

  //         "&:hover": {
  //           backgroundColor: "#EEEAFB", // Change background on hover
  //         },
  //       },
  //       content: {
  //         margin: 0,
  //       },
  //     },
  //   },
  //   MuiAccordionDetails: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: "#ffffff",
  //       },
  //     },
  //   },
  // },
  // customClasses: {
  //   boxShadow3: {
  //     boxShadow: "rgba(0, 0, 0, 0.5) 0px 1px 2px", // Define your desired box shadow here
  //   },
  //   // Add other custom classes as needed
  // },
});

export default theme;
